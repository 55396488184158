
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Raleway:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ostrich+Sans:wght@400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family:  'Raleway','Montserrat','Ostrich Sans',  sans-serif;
}

  /* Nature quote */
  .quotes {
    text-align: center;
    margin-top: 4rem;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
  }
  .quotes-container {
    text-justify: center;
  }
  .quotes-container .quotes p {
    color: rgb(97, 94, 94);
    margin-top: 2rem;
    font-size: 18px;
    font-style: italic;
  }
  h3,
  h2,
  h1 {
    color: rgb(99, 96, 96);
  }
  /* Lazy loader */
  .container-loader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    align-content: center;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  }
  
  .flex {
    min-height: 60pt;
  }
  
  @keyframes loading {
    0% {
      width: 50pt;
      height: 50pt;
      margin-top: 0;
    }
    25% {
      height: 4pt;
      margin-top: 23pt;
    }
    50% {
      width: 4pt;
    }
    75% {
      width: 50pt;
    }
    100% {
      width: 50pt;
      height: 50pt;
      margin-top: 0;
    }
  }
  
  .loader {
    width: 50pt;
    height: 50pt;
    border-radius: 100%;
    border: #6767fa 4pt solid;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
    animation: loading 1s infinite;
  }
  
  .load-text {
    padding-top: 15px;
    text-align: center;
    /* font: 14pt 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    color: lighten(#6767fa, 75%);
  }
  
  /* media screen */
  @media screen and (max-width: 1224px) {
    .classes-main-container .bg-text {
      top: 40%;
    }
  }
  
  @media screen and (max-width: 1024px) {
    .classes-main-container .bg-text {
      top: 40%;
    }
    .classes-main-container .bg-text p {
      font-size: 31px;
    }
  }
  @media screen and (max-width: 920px) {
    .classes-main-container .bg-text p {
      font-size: 28px;
    }
  }
  @media screen and (max-width: 768px) {
    .classes-main-container .bg-image {
      width: 100%;
    }
    .classes-main-container .bg-text {
      top: 29%;
    }
    .classes-main-container .bg-text p {
      font-size: 27px;
    }
    .classes-container .whole-list-classess {
      width: 90%;
    }
  }
  
  @media screen and (max-width: 680px) {
    /* .classes-main-container .bg-text {
          top: 29%;
        } */
  
    .classes-main-container .bg-text p {
      font-size: 22px;
    }
    .classes-container .whole-list-classess h1 {
      font-size: 24px;
    }
  }
  @media screen and (max-width: 480px) {
    .classes-main-container .bg-image {
      height: 190px;
    }
    .classes-main-container .bg-text {
      top: 36%;
    }
    .classes-main-container .bg-text p {
      font-size: 19px;
    }
    .classes-container .whole-list-classess {
      border: none;
    }
  }