.dropdown-footer {
    width: 130px;
    position: absolute;
    top: 100%;
    list-style: none;
    text-align: start;
    z-index: 2;
    margin-left: 7rem;
    color: white;
    /* margin-top: 0.2rem; */
  }
  
  .dropdown-footer.clicked {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 5px;
    opacity: 1;
    transition: all 0.5s ease;
    left: -100%;
  }
  /* .dropdown-footer:after {
    content: "";
    position: absolute;
    top: 0;
    left: 25%;
    width: 0;
    height: 0;
    border: 17px solid transparent;
    border-bottom-color: #486255;
    opacity: 1;
    border-top: 0;
    margin-left: -20px;
    margin-top: -15px;
  } */
  .dropdown-footer li {
    /* background-color: #929292; */
    background-color: #486255;
    cursor: pointer;
    color: white;
    text-align: center;
    font-size: 1rem;
    /* z-index:1; */
  }
  
  .dropdown-footer li:hover {
    background: #050505;
    color: #f8f6f6;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    /* color: rgb(251, 249, 249); */
    padding: 6px;
  }
  
  @media screen and (max-width: 768px) {
    .dropdown-footer {
      top: 89%;
      margin-left: 3rem;
    }
    .dropdown-footer:after {
      left: 80%;
    }
    .dropdown-footer li {
      font-size: 0.8rem;
      background-color: #486255;
      color: white;
    }
  
    .dropdown-footer:after {
      margin-left: -1px;
      margin-top: -17px;
      left: 30%;
    }
  }
  /* @media only screen and (max-width: 600px) {
    .dropdown-footer {
     
      right: 26%;
    }
  } */